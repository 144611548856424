<template>
  <v-flex class="overflow-y-auto" md8 mx-auto>
    <v-card class="transparent elevation-0">
      <!-- <v-card-text class="text-center">
        <v-btn color="accent" @click="shareProfileDialog = !shareProfileDialog">
          {{ $vuetify.lang.t('$vuetify.settings.shareYourProfile') }}
        </v-btn>
      </v-card-text> -->
      <v-card-text>
        {{ $vuetify.lang.t('$vuetify.settings.language') }}
        <v-flex xs12 sm4 md4 class="px-0">
          <v-select
            v-model="selectedLanguage"
            :items="[
              {
                language_name: 'Estonian',
                language_code: 'et',
                flag_code: 'ee'
              }
            ]"
            cache-items
            :label="$vuetify.lang.t('$vuetify.settings.selectLanguage')"
            item-text="language_name"
            item-value="language_name"
            return-object
            outlined
            hide-details
            dense
          >
            <template v-slot:selection="{ item }">
              <span
                :class="`flag-icon flag-icon-${item.flag_code} mr-3`"
              ></span>
              {{ item.language_name }}
            </template>
            <template v-slot:item="{ item }">
              <span
                :class="`flag-icon flag-icon-${item.flag_code} mr-3`"
              ></span>
              {{ item.language_name }}
            </template>
          </v-select>
        </v-flex>
      </v-card-text>
      <!-- <v-card-text>
        <span>
          {{ $vuetify.lang.t('$vuetify.settings.pushNotifications') }}
        </span>
        <push-notifications-switch />
      </v-card-text> -->
      <v-card-text>
        <span>{{ $vuetify.lang.t('$vuetify.settings.addPhoneNumber') }}</span>
        <!-- <v-text-field
            name="phone_num"
            type="number"
            label="Phone number"
            class="mr-4"
          ></v-text-field> -->
        <phone-select
          class="mt-4"
          @phone-dialling-code-changed="phoneDiallingCodeChanged"
          @phone-number-changed="phoneNumberChanged"
        />

        <!-- <VuePhoneNumberInput v-model="phoneNumber" class="py-4 px-0" /> -->
        <!-- <v-text-field
          name="phone"
          label="Phone number"
          solo
          hide-details
          class="mt-4"
        >
          <template v-slot:append-outer>
            <v-btn outlined>Send verification code</v-btn>
          </template>
        </v-text-field> -->
      </v-card-text>
      <!-- TODO:: Enable if backend is ready for card payments -->
      <!-- <v-card-text>
        <span>
          {{ $vuetify.lang.t('$vuetify.settings.addBankAccountNumber') }}
        </span>
        <v-card-text class="d-block d-sm-flex d-md-flex px-0">
          <v-text-field
            v-model="bankAccountNumber"
            outlined
            hide-details
            dense
            class="iban-input"
            prepend-inner-icon="account_balance"
            name="name"
            label="IBAN"
            :loading="validatingIban"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="accent"
            :style="$vuetify.breakpoint.smAndDown ? 'float: right;' : ''"
            :disabled="!bankAccountValid"
            :loading="
              loading.state === true &&
                loading.type === 'updateCurrentUserWithData'
            "
            @click="addBankAccountNumber()"
          >
            {{
              !user.bankAccountNumber
                ? $vuetify.lang.t('$vuetify.settings.addAccount')
                : $vuetify.lang.t('$vuetify.settings.update')
            }}
            +
          </v-btn>
        </v-card-text>
      </v-card-text> -->
      <!-- <v-card-text>
        <span>{{ $vuetify.lang.t('$vuetify.settings.addCreditCard') }}</span>
        <v-card-text class="d-block d-md-flex px-0">
          <stripe-element
            type="card"
            :stripe="stripeKey"
            :style="
              $vuetify.breakpoint.smAndDown ? 'width: 100%;' : 'width: 50%'
            "
            placeholder="tere"
            @change="cdcompleted = $event.complete"
          />
          <v-spacer></v-spacer>
          <v-btn
            text
            color="accent"
            :style="$vuetify.breakpoint.smAndDown ? 'float: right;' : ''"
            :disabled="!cdcompleted"
            :loading="loading.state === true && loading.type === 'addNewSource'"
            @click="addCreditCard"
          >
            {{ $vuetify.lang.t('$vuetify.settings.addCard') }} +
          </v-btn>
        </v-card-text>
        <div
          class="overflow-y-auto"
          :style="{ 'max-height': '20vh', width: '100%' }"
        >
          <v-list
            v-if="sources.length > 0 || loading.state === false"
            color="transparent"
          >
            <v-list-item
              v-for="source in sources"
              :key="source.id"
              class="px-0"
            >
              <v-list-item-avatar>
                <font-awesome-icon
                  v-if="source.brand.toLowerCase() !== 'american express'"
                  :icon="['fab', `cc-${source.brand.toLowerCase()}`]"
                />
                <font-awesome-icon
                  v-if="source.brand.toLowerCase() === 'american express'"
                  :icon="['fab', 'cc-amex']"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  ••••&nbsp;{{ source.last4 }}&nbsp;&nbsp;&nbsp;&nbsp;
                  {{ source.exp_month }}/{{ source.exp_year }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action @click="removeCard(source)">
                <v-btn
                  text
                  color="primary"
                  :loading="
                    source.id === itemInAction &&
                      loading.state === true &&
                      loading.type === 'removeSource'
                  "
                >
                  {{ $vuetify.lang.t('$vuetify.settings.remove') }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <loader v-else :auto-height="true" />
        </div>
      </v-card-text> -->
      <v-card-text>
        <span>{{
          $vuetify.lang.t('$vuetify.changePasswordForm.changePassword')
        }}</span>
        <change-password-form />
      </v-card-text>
      <v-card-text>
        <v-btn color="primary darken-3" @click="linkWithFacebook()">
          <font-awesome-icon :icon="['fab', 'facebook-square']" class="mr-4" />
          Lingi konto
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" outlined :to="'/logout'">Logi välja</v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>
<script>
// import { StripeElement, Stripe } from 'vue-stripe-better-elements'
import { mapGetters, mapActions } from 'vuex'

import 'flag-icon-css/css/flag-icon.css'
import { firebase } from '@firebase/app'

export default {
  name: 'Settings',
  components: {
    // PushNotificationsSwitch: () =>
    //   import(
    //     /* webpackChunkName: "PushNotificationsSwitch" */ '@/components/PushNotificationsSwitch'
    //   ),
    PhoneSelect: () =>
      import(
        /* webpackChunkName: "PhoneSelect" */ '@/components/Profile/PhoneSelect'
      ),
    ChangePasswordForm: () =>
      import(
        /* webpackChunkName: "ChangePasswordForm" */ '@/components/Login/ChangePasswordForm'
      )
    // StripeElement,
    // Loader: () => import(/* webpackChunkName: "Loader" */ '@/components/Loader')
  },
  data() {
    return {
      selectedLanguage: {
        language_name: 'Estonian',
        language_code: 'et',
        flag_code: 'ee'
      },
      stripeKey: 'pk_test_qnoWqRpU8taJnAlecjTHJWNr',
      cdcompleted: false,
      shareProfileDialog: false,
      itemInAction: null,
      bankAccountNumber: '',
      bankAccountValid: false,
      validatingIban: false,
      diallingCode: '',
      phoneNumber: ''
    }
  },
  computed: {
    ...mapGetters('authentication', ['user']),
    ...mapGetters('payments', ['sources']),
    ...mapGetters('app', ['loading'])
  },
  watch: {
    async bankAccountNumber(newValue) {
      if (newValue.length > 15) {
        this.validatingIban = true
        this.bankAccountValid = await fetch(
          `https://openiban.com/validate/${newValue}?getBIC=true&validateBankCode=true`
        )
          .then(response => response.json())
          .then(data => {
            this.validatingIban = false
            return data.valid
          })
      } else {
        this.validatingIban = false
        this.bankAccountValid = false
      }
    },
    selectedLanguage(newValue) {
      this.$vuetify.lang.current = newValue.language_code
    }
  },
  created() {
    // this.getAllUserSources()
    // this.bankAccountNumber = this.user.bankAccountNumber || ''
  },
  methods: {
    phoneNumberChanged(object) {
      this.phoneNumber = object.number
    },
    phoneDiallingCodeChanged(value) {
      this.diallingCode = value
    },
    ...mapActions('authentication', ['updateCurrentUserWithData']),
    ...mapActions('payments', [
      'addNewSource',
      'getAllUserSources',
      'removeSource'
    ]),
    // addCreditCard() {
    //   Stripe.get('card', this.stripeKey)
    //     .createToken()
    //     .then(response => {
    //       this.addNewSource(response)
    //     })
    // },
    removeCard(card) {
      this.itemInAction = card.id
      this.removeSource(card)
    },
    addBankAccountNumber() {
      this.updateCurrentUserWithData({
        this: this,
        data: {
          bankAccountNumber: this.bankAccountNumber
        },
        updatedProperty: this.$vuetify.lang.t(
          '$vuetify.messages.bankAccountNumber'
        )
      })
    },
    linkWithFacebook() {
      const facebookProvider = new firebase.auth.FacebookAuthProvider()
      firebase
        .auth()
        .currentUser.linkWithPopup(facebookProvider)
        .then(() => {
          this.$store.commit(
            'app/setSnackbar',
            {
              show: true,
              type: 'success',
              message: 'Kontod lingitud edukalt.'
            },
            { root: true }
          )
        })
        .catch(() => {
          // Handle Errors here.
          // ...
          this.$store.commit(
            'app/setSnackbar',
            {
              show: true,
              type: 'error',
              message: 'Midagi läks valesti! Proovi hiljem uuesti.'
            },
            { root: true }
          )
        })
    },
    // TODO:: Create possibility to unlink facebook from an account
    // unlinkFacebook() {
    //   firebase
    //     .auth()
    //     .currentUser.unlink(firebase.auth.FacebookAuthProvider())
    //     .then(function() {
    //       // Auth provider unlinked from account
    //       // ...
    //       console.log('unlinked')
    //     })
    //     .catch(function(error) {
    //       // An error happened
    //       console.log(error)
    //       // ...
    //     })
    // },
    showSnackbar(message) {
      this.$store.commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'success',
          message
        },
        { root: true }
      )
    }
  }
}
</script>
<style scoped>
.v-text-field.iban-input {
  width: 100%;
}
</style>
