var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"overflow-y-auto",attrs:{"md8":"","mx-auto":""}},[_c('v-card',{staticClass:"transparent elevation-0"},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.settings.language'))+" "),_c('v-flex',{staticClass:"px-0",attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-select',{attrs:{"items":[
            {
              language_name: 'Estonian',
              language_code: 'et',
              flag_code: 'ee'
            }
          ],"cache-items":"","label":_vm.$vuetify.lang.t('$vuetify.settings.selectLanguage'),"item-text":"language_name","item-value":"language_name","return-object":"","outlined":"","hide-details":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:("flag-icon flag-icon-" + (item.flag_code) + " mr-3")}),_vm._v(" "+_vm._s(item.language_name)+" ")]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:("flag-icon flag-icon-" + (item.flag_code) + " mr-3")}),_vm._v(" "+_vm._s(item.language_name)+" ")]}}]),model:{value:(_vm.selectedLanguage),callback:function ($$v) {_vm.selectedLanguage=$$v},expression:"selectedLanguage"}})],1)],1),_c('v-card-text',[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.settings.addPhoneNumber')))]),_c('phone-select',{staticClass:"mt-4",on:{"phone-dialling-code-changed":_vm.phoneDiallingCodeChanged,"phone-number-changed":_vm.phoneNumberChanged}})],1),_c('v-card-text',[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.changePasswordForm.changePassword')))]),_c('change-password-form')],1),_c('v-card-text',[_c('v-btn',{attrs:{"color":"primary darken-3"},on:{"click":function($event){return _vm.linkWithFacebook()}}},[_c('font-awesome-icon',{staticClass:"mr-4",attrs:{"icon":['fab', 'facebook-square']}}),_vm._v(" Lingi konto ")],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":"","to":'/logout'}},[_vm._v("Logi välja")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }